export const useTimeline = () => {
  const { $JsonRaw }: any = useNuxtApp()
  const router: any = useRouter()
  const route: any = useRoute()

  const showCreatePost = useState<any>('showCreatePost', () => false)
  const setShowCreatePost = (t: boolean) => {
    showCreatePost.value = t || false
  }

  const timelineDetail = useState<any>('timelineDetail', () => null)
  const setTimelineDetail = (t: any) => {
    timelineDetail.value = t || null
  }

  const timelineRepost = useState<any>('timelineRepost', () => null)
  const setTimelineRepost = (t: any) => {
    timelineRepost.value = t || null
  }

  const TimelineData = useState<any>('TimelineData', () => {
    return {
      index: {
        loading: false,
        total: 0,
        data: []
      }
    }
  })

  const TimelineAction = async (act: string, id: string, isActive?: boolean) => {
    if (act === 'delete') {
      if (id === route.params?.id) {
        router.replace('/')
      }

      const keys = Object.keys(TimelineData.value)
      for (let k = 0; k < keys.length; k++) {
        const kk = TimelineData.value[keys[k]]
        if (kk) {
          const indexData = TimelineData.value[keys[k]].data.findIndex((r: any) => r.id === id)
          if (indexData > -1) {
            TimelineData.value[keys[k]].data.splice(indexData, 1)
          }
        }
      }
      return
    }
    if (id === timelineDetail.value?.id) {
      timelineDetail.value[act === 'like' ? 'me_liked' : 'me_bookmarked'] = isActive ? false : true
      if (isActive) {
        timelineDetail.value[act === 'like' ? 'count_likes' : 'count_bookmarks'] = parseInt(timelineDetail.value[act === 'like' ? 'count_likes' : 'count_bookmarks']) - 1
      } else {
        timelineDetail.value[act === 'like' ? 'count_likes' : 'count_bookmarks'] = parseInt(timelineDetail.value[act === 'like' ? 'count_likes' : 'count_bookmarks']) + 1
      }
    }
    const keys = Object.keys(TimelineData.value)
    for (let k = 0; k < keys.length; k++) {
      const kk = TimelineData.value[keys[k]]
      if (kk) {
        const indexData = TimelineData.value[keys[k]].data.findIndex((r: any) => r.id === id)
        if (indexData > -1) {
          TimelineData.value[keys[k]].data[indexData][act === 'like' ? 'me_liked' : 'me_bookmarked'] = isActive ? false : true
          if (isActive) {
            TimelineData.value[keys[k]].data[indexData][act === 'like' ? 'count_likes' : 'count_bookmarks'] = parseInt(TimelineData.value[keys[k]].data[indexData][act === 'like' ? 'count_likes' : 'count_bookmarks']) - 1
          } else {
            TimelineData.value[keys[k]].data[indexData][act === 'like' ? 'count_likes' : 'count_bookmarks'] = parseInt(TimelineData.value[keys[k]].data[indexData][act === 'like' ? 'count_likes' : 'count_bookmarks']) + 1
          }
        }
      }
    }
    const a = await useApiPost(`/timeline/${id}/${act}/${!isActive}`)
    // if (a && a.data) {
    // }
  }

  const TimelineGet = async (key: any, q: string, isNextPage?: boolean) => {
    if (!TimelineData.value[key]) {
      TimelineData.value[key] = {
        loading: false,
        total: 0,
        data: []
      }
    }
    TimelineData.value[key].loading = true
    const req = await useApiGet(`/timeline${q || ''}`)
    if (req && req.status && req.data) {
      const rd = $JsonRaw(req.data)
      if (isNextPage) {
        (rd.data || []).map((r: any) => {
          TimelineData.value[key].data.push(r)
        })
        TimelineData.value[key].total += parseInt(rd.total) || 0
      } else {
        TimelineData.value[key].total = parseInt(rd.total) || 0
        TimelineData.value[key].data = rd.data || []
      }
    }
    TimelineData.value[key].loading = false
  }

  return {
    TimelineData,
    TimelineGet,
    showCreatePost,
    setShowCreatePost,
    timelineDetail,
    setTimelineDetail,
    timelineRepost,
    setTimelineRepost,
    TimelineAction
  }
}
